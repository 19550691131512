import * as React from "react"
import {Link} from "gatsby"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";

const CovidPage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }

    useEffect(() => {
        Axios.get("/log.php?page=covid_"+lang);
    },[])

    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu changeLang={changeLang} lang={lang}/>

            <div id="duogram"></div>

            {lang != "en"&&

                <>
                    <h1>Covid-restriksjoner</h1>
                    <p>
                        De fleste covid-restriksjoner er nå fjernet i Hellas,
                        men noen unntak er det fremdeles. Det er eksempelvis påbudt
                        å bruke munnbind som passasjer i taxi.
                    </p>
                </>

            }
            {lang == "en" &&
                <>
                    <h1>Covid restrictions</h1>

                    <p>
                        Most covid-restrictions are now lifted in Greece. But there are some exceptions. Taxi passengers are required to use a face mask are among the most relevant.
                    </p>
                </>
            }

        </main>
    )
}

export default CovidPage
